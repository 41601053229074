/**
 * Reads file content as text.
 *
 * @param {File} file Input file
 *
 * @returns {Promise<string>} File content
 */
export function readTextFile (file) {
  // eslint-disable-next-line promise/avoid-new
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.addEventListener('load', e => resolve(e.target.result))
    reader.addEventListener('error', reject)

    reader.readAsText(file)
  })
}
