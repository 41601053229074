<template>
  <div class="dealer-card">
    <div class="dealer-card__head">
      <div class="dealer-card__head-avatar">
        <lazy-ava-img
          class="dealer-card__head-avatar-img"
          :alt="dealerFullName"
          :title="dealerFullName"
          :src="dealerEntry.avatarLink"
          :placeholder-path="avaPh64Path"
        />
      </div>
      <div class="dealer-card__head-dealer">
        <dealer-card-name-edit
          class="dealer-card-name-edit__head-dealer-email-edit"
          :dealer-entry="dealerEntry"
        />
      </div>
    </div>
    <div class="dealer-card__subnav">
      <div class="dealer-card__subnav-container">
        <div class="dealer-card__subnav-tabs">
          <button
            class="dealer-card__tab"
            :class="{ 'dealer-card__tab_active':
              dealerTabLocal === DEALER_CARD_TABS.attributes
            }"
            @click="setDealerTab(DEALER_CARD_TABS.attributes)"
          >
            {{ $t('ATTRIBUTES_TAB') }}
          </button>
        </div>
      </div>
    </div>

    <div class="dealer-card__tab-wrap">
      <template v-if="dealerTabLocal === DEALER_CARD_TABS.attributes">
        <dealer-card-tab-attrs
          class="dealer-card__tab-component"
          :dealer-entry="dealerEntry"
        />
      </template>
    </div>
    <div class="dealer-card__footer">
      <ui-row class="dealer-card__footer-row">
        <ui-col
          class="dealer-card__footer-left"
          lg="7"
          xs="12"
        >
          <div class="dealer-card__footer-created-at">
            {{ $t('CREATED_AT_TXT', { date: dealerCreatedAt }) }}
          </div>
        </ui-col>

        <ui-col
          class="dealer-card__footer-right"
          lg="5"
          xs="12"
          xs-offset="0"
        >
          <dealer-card-remove-btn
            class="dealer=card__remove-btn"
            :dealer-entry="dealerEntry"
          />
        </ui-col>
      </ui-row>
    </div>
  </div>
</template>

<script>
import { UiCol, UiRow } from '@shelf.network/ui-kit'
import LazyAvaImg from 'Common/LazyAvaImg'

import DealerCardNameEdit from './DealerCardNameEdit'
import DealerCardRemoveBtn from './DealerCardRemoveBtn'
import DealerCardTabAttrs from './DealerCardTabAttrs'

import { DEALER_CARD_TABS } from '../../constants'
import avaPh64Path from 'Assets/icons/ava-ph-64.svg'
import { UserEntry } from 'Models/UserEntry'

import { safeUserName } from 'Utils/safeUserName'
import formatFullName from 'Utils/formatFullName'
import { formatDateTimeRelative } from 'Utils/dateHelpers'

const DEFAULT_TAB = DEALER_CARD_TABS.attributes

export default {
  name: 'dealer-card',
  components: {
    UiCol,
    UiRow,

    LazyAvaImg,
    DealerCardNameEdit,
    DealerCardRemoveBtn,
    DealerCardTabAttrs,
  },

  props: {
    dealerEntry: {
      type: UserEntry,
      required: true
    },

    dealerTab: {
      type: String,
      default: DEFAULT_TAB,
      validator: val => !val || Object.values(DEALER_CARD_TABS).includes(val),
    },
  },

  data () {
    return {
      dealerTabLocal: DEFAULT_TAB,
      DEALER_CARD_TABS,
      avaPh64Path,
    }
  },

  computed: {
    dealerCreatedAt () {
      return formatDateTimeRelative(this.dealerEntry.createdAt)
    },

    dealerFullName () {
      return safeUserName(formatFullName(this.dealerEntry))
    },
  },

  watch: {
    dealerTab: {
      handler (value, oldValue) {
        if (!value) {
          return this.setDealerTab(DEFAULT_TAB)
        }
      },
      immediate: true
    },
  },

  methods: {
    setDealerTab (name) {
      this.dealerTabLocal = name
      this.$emit('update:dealerTab', name)
    },
  },

  metaInfo () {
    // TODO: broken: does not appear in the title
    return { title: this.dealerFullName }
  },
}
</script>

<style scoped lang="scss">
.dealer-card {
  @include respond(--ava-size, 4em, 4em, 4em, 3em);

  &__head {
    display: grid;
    grid: auto / var(--ava-size) minmax(0, 1fr);
    align-items: center;
    padding: 2em 2.75em;
    max-width: 100%;
    gap: 1em;

    &-avatar {
      align-self: start;
      position: relative;
      border-radius: 0.5em;
      width: var(--ava-size);
      height: var(--ava-size);
      background: $color-light-grey;

      @include aspect-ratio();

      --ratio: calc(1 / 1);

      &-img {
        --ui-img-border-radius: 0.5em;

        width: var(--ava-size);
        height: var(--ava-size);
        overflow: hidden;

        @include aspect-ratio();

        --ratio: calc(1 / 1);
      }
    }
  }

  &__subnav {
    // needs to be a component or mixin
    background-color: #f0f2f5;
    max-width: 100%;

    &-tabs {
      margin-right: auto;
      margin-left: 0;
      display: flex;
      height: 100%;
    }

    &-container {
      display: flex;
      height: 5em;
      align-items: center;
      padding: 0 2.75em;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      width: 100%;

      @include scrollbar-invisible();
    }
  }

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 2em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;
    background: none;
    border: none;

    &-circle {
      vertical-align: sub;
      font-size: 1.1em;
    }

    &-notify {
      color: $color-sys-warning;
      transform: translateY(-0.4em) translateX(-0.2em);
      display: inline-block;
      vertical-align: text-top;
      font-size: 0.8em;
      visibility: hidden;
      width: 0;

      &_show {
        visibility: visible;
      }
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &_active &-circle::before {
      content: map-get($icons, circle-full);
    }
  }

  &__tab-wrap {
    padding: 2em 1.75em 3em 1.75em;
  }

  &__footer {
    border-top: 1px solid $color-grey;
    padding: 2em 1.75em;

    &-created-at {
      color: $color-ui-secondary;
      display: flex;
      align-items: center;
    }

    &-left {
      text-align: left;
      display: flex;

      @include respond-below(sm) {
        display: block;
      }
    }

    &-right {
      text-align: right;
    }
  }

  @include respond-below(sm) {
    &__head {
      margin-top: 2.9em;
      justify-content: start;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "ATTRIBUTES_TAB": "DEALER DETAILS",
    "CREATED_AT_TXT": "Created: {date}"
  },
  "ka": {
    "ATTRIBUTES_TAB": "ᲓᲘᲚᲔᲠᲘ ᲘᲜᲤᲝ",
    "CREATED_AT_TXT": "შექმნილია: {date}"
  },
  "ru": {
    "ATTRIBUTES_TAB": "ПОЛЬЗОВАТЕЛЬ",
    "CREATED_AT_TXT": "Создан: {date}"
  },
  "uk": {
    "ATTRIBUTES_TAB": "ДАНІ КОРИСТУВАЧА",
    "CREATED_AT_TXT": "Створений: {date}"
  }
}
</i18n>
