<template>
  <ui-portal>
    <div
      class="dealer-card-modal"
      :class="'dealer-card-modal_' +
        (isAnimated ? 'animate' : 'before-animate')"
      :style="{
        '--dealer-animation': 'appear-animation-' + animationDirection
      }"
      v-if="isOpen"
    >
      <button
        class="dealer-card-modal__close-btn"
        @click="$emit('close')"
      >
        <ui-icon icon="close" />
      </button>

      <div class="dealer-card-modal__pager dealer-card-modal__pager_prev">
        <button
          class="dealer-card-modal__pager-link"
          v-if="prevDealer.id"
          @click="goPrevDealer(prevDealer.id)"
        >
          <ui-icon
            icon="arrow-left"
            class="dealer-card-modal__pager-icon"
          />
          <span class="dealer-card-modal__pager-name">
            {{ prevDealer.fullName }}
          </span>
        </button>
      </div>

      <div class="dealer-card-modal__pager dealer-card-modal__pager_next">
        <button
          class="dealer-card-modal__pager-link"
          v-if="nextDealer.id"
          @click="goNextDealer(nextDealer.id)"
        >
          <span class="dealer-card-modal__pager-name">
            {{ nextDealer.fullName }}
          </span>
          <ui-icon
            icon="arrow-right"
            class="dealer-card-modal__pager-icon"
          />
        </button>
      </div>

      <div class="dealer-card-modal__content-wrap">
        <dealer-card
          v-if="dealerEntry.id === dealerId"
          :dealer-entry="dealerEntry"
          :dealer-tab="dealerTab"
          @update:dealerTab="$emit('update:dealerTab', $event)"
        />

        <template v-else-if="!isLoading && isNotFoundCurrentDealer">
          <p class="dealer-card-modal__not-found-err">
            {{ $t('DEALER_LOAD_FAILED_MSG') }}
          </p>
        </template>

        <dealer-card-skeleton v-else />
      </div>
    </div>
  </ui-portal>
</template>

<script>
import { UiIcon, UiPortal, BodyScrollPreventer } from '@shelf.network/ui-kit'
import DealerCard from './DealerCard'
import DealerCardSkeleton from './DealerCardSkeleton'

import { mapActions } from 'vuex'
import { extensionDealersActions, extensionDealersMutations } from '../../store/types'

import { validateArrayOf } from 'Models/modelUtils'
import { UserEntry } from 'Models/UserEntry'
import { Identity } from 'Models/Identity'
import { wait } from 'Utils/wait'

import { LoneSdkCall } from 'Utils/LoneSdkCall'
import { sdk } from 'Services/shelfNetworkSdk'

const loneSdkCall = new LoneSdkCall()

export default {
  name: 'dealer-card-modal',
  components: {
    UiIcon,
    UiPortal,
    DealerCard,
    DealerCardSkeleton,
  },

  props: {
    dealersList: {
      type: Array,
      validator: list => validateArrayOf(Identity)(list),
      default: () => ([]),
    },

    isDealersLoading: {
      type: Boolean,
      default: false,
    },

    dealerId: {
      type: String,
      default: '',
    },

    dealerTab: {
      type: String,
      default: '',
    },
  },

  data () {
    return {
      dealerEntry: new UserEntry(),
      isLoadingCurrentDealer: false,
      isNotFoundCurrentDealer: false,
      isAnimated: true,
      animationDirection: 'up',
      storeUnsubscriber: () => { },
    }
  },

  computed: {
    currentDealer () {
      return this.dealersList.find(el => el.id === this.dealerId) || {}
    },

    isOpen () {
      return Boolean(this.dealerId)
    },

    isLoading () {
      return this.isDealersLoading || this.isLoadingCurrentDealer
    },

    prevDealer () {
      if (!this.currentDealer.id) return {}
      const index = this.dealersList
        .findIndex(l => l.id === this.currentDealer.id)
      if (index < 0) return {}
      return this.dealersList[index - 1] || {}
    },

    nextDealer () {
      if (!this.currentDealer.id) return {}
      const index = this.dealersList
        .findIndex(l => l.id === this.currentDealer.id)
      if (index < 0) return {}
      return this.dealersList[index + 1] || {}
    }
  },

  watch: {
    isOpen: {
      immediate: true,
      handler (newV, oldV) {
        if (newV && !oldV) this.animationDirection = 'up'
      }
    }
  },

  created () {
    this.$watch(
      () => ([this.dealerId, this.dealersList, this.isDealersLoading]),
      async () => {
        await this.loadDealerEntry()
      },
      { immediate: true }
    )

    this.$watch(
      'isOpen',
      (newV, oldV) => newV
        ? BodyScrollPreventer.on()
        : oldV && BodyScrollPreventer.off(),
      { immediate: true },
    )

    this.storeUnsubscriber = this.$store.subscribe(async (mutation, state) => {
      let id
      switch (mutation.type) {
        case `ui/extension-dealers/${extensionDealersMutations.UPDATE_ITEM}`:
          id = mutation.payload.id; break

        case `ui/extension-dealers/${extensionDealersMutations.REMOVE_ITEM}`:
          id = mutation.payload; break

        default:
          return
      }

      if (!this.dealerId || this.dealerId !== id || !this.dealersList.length) {
        return
      }

      const nextDealerId = this.nextDealer.id
      const prevDealerId = this.prevDealer.id
      const isWasPresent = this.dealersList.find(el => el.id === this.dealerId)
      await this.$nextTick()
      const isNowPresent = this.dealersList.find(el => el.id === this.dealerId)

      if (isWasPresent && !isNowPresent) {
        if (nextDealerId) {
          this.goNextDealer(nextDealerId)
        } else if (prevDealerId) {
          this.goPrevDealer(prevDealerId)
        } else {
          this.$emit('close')
        }
      }
    })
  },

  beforeDestroy () {
    if (this.isOpen) BodyScrollPreventer.off()
    this.storeUnsubscriber()
  },

  methods: {
    ...mapActions('ui/extension-dealers', {
      loadIdentity:
      extensionDealersActions.LOAD_IDENTITY,
    }),

    async loadDealerEntry () {
      if (this.currentDealer instanceof UserEntry) {
        this.dealerEntry = this.currentDealer
        return
      }

      const { data: dealer } = await loneSdkCall.takeLatest(
        sdk.horizon.account.get(this.dealerId)
      )

      const dealerEntry = new UserEntry(dealer)
      await this.loadIdentity(dealerEntry.email)
      this.dealerEntry = dealerEntry
    },

    async animate (direction) {
      this.isAnimated = false

      // wait for vdom -> dom
      await this.$nextTick()

      // and then wait a bit more (hack)
      await wait(40)

      this.isAnimated = true
      this.animationDirection = direction
    },

    goNextDealer (id = '') {
      this.animate('forward')
      this.$emit('update:dealerId', id || this.nextDealer.id)
      // TODO: load more
    },

    goPrevDealer (id = '') {
      this.animate('backward')
      this.$emit('update:dealerId', id || this.prevDealer.id)
    },
  },
}
</script>

<style lang="scss">
@keyframes appear-animation-backward {
  from {
    opacity: 0;
    transform: translateX(-3em);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes appear-animation-forward {
  from {
    opacity: 0;
    transform: translateX(3em);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes appear-animation-up {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
</style>

<style scoped lang="scss">
.dealer-card-modal {
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.75);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include scrollbar-invisible();

  &_before-animate /deep/ {
    .dealer-card__head,
    .dealer-card__subnav-container,
    .dealer-card__tab-component,
    .dealer-card__comment-comp,
    .dealer-card__footer-row {
      opacity: 0;
    }
  }

  &_animate /deep/ {
    .dealer-card__head,
    .dealer-card__subnav-container,
    .dealer-card__tab-component,
    .dealer-card__comment-comp,
    .dealer-card__footer-row {
      animation-name: var(--dealer-animation, appear-animation-up);
      animation-duration: 0.6s;
      animation-iteration-count: 1;
    }
  }

  &__content-wrap {
    min-height: 30vh;
    width: 60%;
    max-width: 70em;
    background: #fff;
    margin: 7em auto;
    border-radius: $layout-border-radius;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__pager {
    width: 15%;
    height: 10%;
    position: fixed;
    top: 45%;
    min-height: 5em;

    &-link {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      height: 100%;
      width: 100%;
      color: $color-dark-grey;
      text-decoration: none;
      background: none;
      border: none;

      &:hover {
        color: $color-light;
      }
    }

    &-name {
      font-size: 1.2em;
      display: block;
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-link:hover &-name {
      text-decoration: underline;
    }

    &-icon {
      font-size: 2em;
      padding: 0 1em;
      display: block;
    }

    &_next {
      text-align: right;
      right: 1em;
    }

    &_prev {
      text-align: left;
      left: 1em;
    }
  }

  &__close-btn {
    position: fixed;
    color: $color-dark-grey;
    top: 1em;
    bottom: 1em;
    left: 1em;
    box-sizing: border-box;
    font-size: 3em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    width: calc(100vw - 2em);
    background: none;
    border: none;

    &:hover {
      color: $color-light;
    }
  }

  &__not-found-err {
    color: $color-flag-is-error;
    text-align: center;
    padding: 1em;
    height: 100%;
    margin: auto;
    font-weight: 700;
  }

  @include respond-below(sm) {
    padding: 0;
    border-radius: 0;
    display: block;

    &__pager {
      display: none;
    }

    &__content-wrap {
      width: 100%;
      min-height: 100vh;
      border-radius: 0;
      margin: 0;
    }

    &__close-btn {
      font-size: 2em;
      width: calc(100vw - 1em);
      position: absolute;
      bottom: auto;
      left: auto;
      z-index: z-index(1);
    }
  }
}
</style>

<i18n>
{
  "en": {
    "DEALER_LOAD_FAILED_MSG": "Could not load the provided dealer. Please ensure you are using the valid link or contact the system owner."
  },
  "ka": {
    "DEALER_LOAD_FAILED_MSG": "დილერი ვერ ჩაიტვირთა. დარწმუნდი რომ ინფორმაცია სწორია, ან დაუკავშირდი ადმინისტრატორს."
  },
  "ru": {
    "DEALER_LOAD_FAILED_MSG": "Не удалось загрузить дилера. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "DEALER_LOAD_FAILED_MSG": "Не вдалось завантажити дилера. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
