import { Command } from '@/commands/Command'
import { Identity } from 'Models/Identity'
import { UserEntry } from 'Models/UserEntry'

import { rootGet, rootDispatch } from 'Store/helpers/rootHelpers'
import { safeUserName } from 'Utils/safeUserName'
import { extensionDealersGetters, extensionDealersActions } from '../store/types'

import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'

/**
 * @typedef {object} DealerCommandArgsDef
 * @property {Identity|UserEntry} identity Identity to operate over
 *
 * @typedef {import('@/commands/Command').CommandArgs} CommandArgs
 * @typedef {CommandArgs & DealerCommandArgsDef} DealerCommandArgs
 */

export class DealerCommand extends Command {
  /**
   * Create a configured DealerCommand command
   * @param {DealerCommandArgs} opts
   */
  constructor (opts) {
    super(opts)

    const isValidType = opts.dealerEntry instanceof Identity ||
      opts.dealerEntry instanceof UserEntry
    if (!isValidType) {
      throw TypeError('dealerEntry should be an instance of Identity or UserEntry')
    }

    this._dealerEntry = opts.dealerEntry
  }

  _getName () {
    return this._dealerEntry instanceof Identity
      ? this._dealerEntry.fullName
      : safeUserName(this._dealerEntry.firstName)
  }

  _getStoredItem () {
    const list = rootGet(`ui/extension-dealers/${extensionDealersGetters.LIST_RAW}`)
    const index = list.findIndex(el => el.id === this._dealerEntry.id)

    return {
      index,
      item: list[index],
    }
  }

  _removeStoredItem (id) {
    return rootDispatch(`ui/extension-dealers/${extensionDealersActions.REMOVE_ITEM}`, id)
  }

  _updateStoredItem (opts) {
    const { item } = this._getStoredItem()
    const updated = merge(cloneDeep(item), opts)
    return rootDispatch(`ui/extension-dealers/${extensionDealersActions.UPDATE_ITEM}`, updated)
  }
}
