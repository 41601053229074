<template>
  <list-params
    class="dealers-list-params"
    v-model="form"
    :initial-value="value"
    @form-apply="$emit('input', form)"
  >
    <template slot="form-rows">
      <div class="dealers-list-params__row">
        <label class="list-params-row__label">
          {{ $t('LOGIN_LBL') }}
        </label>

        <ui-select
          v-model="form.login"
          fill="frame"
          look="secondary"
          class="dealers-list-params__copart-login"
          :options="managedLoginsOptions"
          :placeholder="$t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
          :search-placeholder="$t('COMMON.SELECT_FIELD.SEARCH_PH')"
          has-filter
        />
      </div>
    </template>
  </list-params>
</template>

<script>
import { UiSelect } from '@shelf.network/ui-kit'
import { ListParams } from 'Common/ListParams'

import { mapGetters } from 'vuex'
import { extensionDealersGetters } from '../store/types'

export default {
  name: 'dealers-list-params',
  components: {
    UiSelect,
    ListParams,
  },

  props: {
    value: { type: Object, required: true },
  },

  data: _ => ({
    form: {
      login: ''
    },
  }),

  computed: {
    ...mapGetters('ui/extension-dealers', {
      managedLogins: extensionDealersGetters.MANAGED_LOGINS,
    }),

    managedLoginsOptions () {
      return this.managedLogins.map(value => ({
        value,
        label: value
      }))
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/ListParams/styles/list-params-row.scss";
</style>

<i18n>
{
  "en": {
    "LOGIN_LBL": "BY IMPACT LOGIN"
  },
  "ka": {
    "LOGIN_LBL": "IMPACT-ᲖᲔ ᲨᲔᲡᲕᲚᲐ ᲛᲘᲮᲔᲓᲕᲘᲗ"
  },
  "ru": {
    "LOGIN_LBL": "ПО ЛОГИНУ IMPACT"
  },
  "uk": {
    "LOGIN_LBL": "ЗА ЛОГІНОМ IMPACT"
  }
}
</i18n>
