<template>
  <router-link
    :to="{ params: { dealerId: identity.id }, query: $route.query }"
    class="op-edit-details dealer-op dealer-op__btn"
  >
    <ui-icon
      class="dealer-op__btn-ico"
      icon="pen"
    />

    <span class="dealer-op__btn-txt">
      {{ $t('EDIT_DETAILS_BTN') }}
    </span>
  </router-link>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { Identity } from 'Models/Identity'

export default {
  name: 'op-edit-details',
  components: { UiIcon },

  props: {
    identity: { type: Identity, required: true },
  },

  data () {
    return {
      isEditDetailsModalShown: false,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/dealers-operations.scss";

.op-edit-details {
  text-decoration: none;
}
</style>

<i18n>
{
  "en": {
    "EDIT_DETAILS_BTN": "Edit dealer details"
  },
  "ka": {
    "EDIT_DETAILS_BTN": "ინფორმაციის განახლება"
  },
  "ru": {
    "EDIT_DETAILS_BTN": "Изменить информацию"
  },
  "uk": {
    "EDIT_DETAILS_BTN": "Змінити інформацію"
  }
}
</i18n>
