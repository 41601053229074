import { DealerCommand } from './DealerCommand'
import { rootDispatch } from 'Store/helpers/rootHelpers'
import { extensionDealersActions } from '../store/types'

export class UnblockDealer extends DealerCommand {
  async execute () {
    try {
      rootDispatch(
        `ui/extension-dealers/${extensionDealersActions.REMOVE_FROM_BLACKLIST}`,
        this._dealerEntry.id
      )

      const msg = this.$t('CMD.USERS.UNBLOCK.SUCCESS_MSG', { name: this._getName() })
      this._reportSuccess(msg)
    } catch (error) {
      const msg = this.$t('CMD.USERS.UNBLOCK.FAILURE_MSG', { name: this._getName() })
      this._reportError(error, msg)
    }
  }
}
