import { sdk } from 'Services/shelfNetworkSdk'
import { Location } from 'Models/Location'

import { DealerCommand } from './DealerCommand'

import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'

/**
 * @typedef {object} UpdateDealerArgsDef
 * @property {object} attributes New attributes to apply to the dealer
 *
 * @typedef {import('./DealerCommand').DealerCommandArgs} DealerCommandArgs
 * @typedef {DealerCommandArgs & UpdateDealerArgsDef} UpdateDealerArgs
 */

export class UpdateDealer extends DealerCommand {
  /**
   * Create a configured UpdateDealer command
   * @param {UpdateDealerArgs} opts
   */
  constructor (opts) {
    super(opts)

    this._attributes = opts.attributes || {}
    this._snapshot = {}
  }

  async execute () {
    this._snapshot = this._prepareSnapshot()

    const basic = get(this._attributes, 'relationships.basic', {})
    const login = basic.login

    try {
      if (login) {
        await sdk.impactAutoBackOffice.updateIdentity({
          identityId: this._dealerEntry.id,
          attributes: { login }
        })
      } else {
        await sdk.horizon.account.update(
          { ...basic, ...this._parseLocation() },
          this._dealerEntry.id
        )
      }

      this._updateStoredItem(this._attributes)

      const msg = this.$t('CMD.USERS.UPDATE.SUCCESS_MSG', { name: this._getName() })
      // Thanks commands, very cool
      const suppressUndo = login && !this._getOldLogin()
      this._reportSuccess(msg, suppressUndo)
    } catch (error) {
      let msg
      const serverErrorMsg = get(error, 'meta.error')

      if (error.httpStatus === 400 && serverErrorMsg) {
        msg = this.$t('CMD.USERS.UPDATE.REQUEST_ERROR_MSG', {
          name: this._getName(),
          error: serverErrorMsg
        })
      } else if (error.httpStatus === 409) {
        if (this.isForcedErrorThrow) {
          this.isFailureReportSuppressed = true
        } else {
          msg = this.$t('CMD.USERS.UPDATE.FAILURE_CONFLICT_MSG')
        }
      }

      msg = msg || this.$t('CMD.USERS.UPDATE.FAILURE_MSG', { name: this._getName() })
      this._reportError(error, msg)
    }
  }

  async undo () {
    try {
      const basic = get(this._attributes, 'relationships.basic', {})
      if (basic.login) {
        await sdk.impactAutoBackOffice.updateIdentity({
          identityId: this._dealerEntry.id,
          attributes: { login: this._getOldLogin() }
        })
      } else {
        await sdk.horizon.account.update(this._snapshot, this._dealerEntry.id)
      }
      this._updateStoredItem(this._snapshot)
    } catch (error) {
      this._reportUndoError(error)
    }
  }

  _prepareSnapshot () {
    const { item } = this._getStoredItem()
    const snapshot = {}
    for (const [key, value] of Object.entries(this._attributes)) {
      snapshot[key] = cloneDeep(item[key]) || this._getFallback(typeof value)
    }
    return snapshot
  }

  _getOldLogin () {
    return get(this._snapshot, 'relationships.basic.login')
  }

  _getFallback (propertyType) {
    let fallback
    switch (propertyType) {
      case 'number': fallback = 0; break
      case 'boolean': fallback = false; break
      default: fallback = ''; break
    }
    return fallback
  }

  _parseLocation () {
    const location = get(this._attributes, 'relationships.location')
    if (!location) return {}

    const locationSplit = Location.fromString(location.id)
    return {
      countryId: Number(locationSplit.countryId),
      region: String(locationSplit.regionId),
      cityId: Number(locationSplit.cityId),
    }
  }
}
