<template>
  <div class="dealers-import">
    <button
      class="dealers-import__btn"
      @click="importDealers"
    >
      {{ $t('IMPORT_BTN') }}
    </button>

    <ui-portal>
      <ui-modal
        v-if="isUpdateModalShown"
        :show-close-button="false"
        class="dealers-import__update-modal"
      >
        <div class="dealers-import__update-modal-content">
          <h3 class="dealers-import__update-modal-title">
            {{ $t('UPDATE_MODAL_TITLE') }}
          </h3>

          <p class="dealers-import__update-modal-msg">
            {{ $t('UPDATE_DEALER_MSG', {
              updated: updatedCount,
              total: totalDealersCount
            }) }}
          </p>

          <ui-progress-linear
            v-if="totalDealersCount"
            class="dealers-import__update-modal-progress"
            :value="updatedCount / totalDealersCount"
            height="8px"
          />

          <div
            v-if="updateErrors.length"
            class="dealers-import__update-modal-errors"
          >
            <p
              v-for="(error, i) in updateErrors"
              :key="i"
              class="dealers-import__update-modal-error"
            >
              {{ error }}
            </p>
          </div>

          <ui-button
            class="dealers-import__update-modal-btn"
            :is-disabled="updatedCount !== totalDealersCount"
            @click="closeUpdateModal"
          >
            {{ $t('CLOSE_BTN') }}
          </ui-button>
        </div>
      </ui-modal>
    </ui-portal>
  </div>
</template>

<script>
import {
  UiModal,
  UiPortal,
  UiButton,
  UiProgressLinear
} from '@shelf.network/ui-kit'

import { selectFile } from 'Utils/selectFile'
import { readTextFile } from 'Utils/readFile'
import { showError } from 'Utils/notifications'

import flatten from 'lodash/flatten'
import { mapActions } from 'vuex'
import { extensionDealersActions } from '../store/types'
import { ImpactLoginUpdateError, DealerNotFoundError } from '../errors'

export default {
  name: 'dealers-export',
  components: {
    UiModal,
    UiPortal,
    UiButton,
    UiProgressLinear
  },

  data () {
    return {
      dealersMap: {},
      isUpdateModalShown: false,
      updatedCount: 0,
      updateErrors: []
    }
  },

  computed: {
    totalDealersCount () {
      return flatten(Object.values(this.dealersMap)).length
    }
  },

  methods: {
    ...mapActions('ui/extension-dealers', {
      updateLogin: extensionDealersActions.UPDATE_LOGIN
    }),

    async importDealers () {
      const CSV_FILE_TYPE = 'text/csv'
      const file = await selectFile([CSV_FILE_TYPE])
      if (!file || file.type !== CSV_FILE_TYPE) return

      try {
        const fileContent = await readTextFile(file)
        this.parseDealers(fileContent)
        await this.updateDealers()
      } catch (error) {
        showError(this.$t('FILE_READ_ERROR'))
        console.error(error)
      }
    },

    parseDealers (raw) {
      const [rawLogins, ...rawDealers] = raw
        .split('\n')
        .map(row => row.split(','))

      const logins = rawLogins
        .map(rawLogin => {
          const matchedLogins = rawLogin.match(/.*?(\d+).*\/.*/)
          return matchedLogins && matchedLogins[1]
        })
        .filter(item => item)

      logins.forEach((login, index) => {
        this.dealersMap[login] = rawDealers
          .map(row => row[index].trim())
          .filter(item => item)
      })
    },

    async updateDealers () {
      this.isUpdateModalShown = true

      await Promise.all(
        Object.entries(this.dealersMap)
          .map(([login, dealers]) => Promise.all(
            dealers.map(search => this.updateDealer(search, login))
          ))
      )
    },

    async updateDealer (email, login) {
      try {
        await this.updateLogin({ email, login })
      } catch (error) {
        let errorMsg
        if (error instanceof DealerNotFoundError) {
          errorMsg = this.$t('NOT_FOUND_ERROR', { email })
        } else if (error instanceof ImpactLoginUpdateError) {
          errorMsg = this.$t('LOGIN_ERROR', {
            email,
            message: error.message
          })
        } else {
          errorMsg = this.$t('UNKNOWN_ERROR', { email })
        }

        this.updateErrors.push(errorMsg)
        console.error(error)
      } finally {
        this.updatedCount++
      }
    },

    closeUpdateModal () {
      this.isUpdateModalShown = false
      this.dealersMap = {}

      this.updateErrors = []
      this.updatedCount = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.dealers-import {
  &__btn {
    background: none;
    border: none;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1.5;
    color: $color-sys-info;
    transition: color 150ms;

    &:hover {
      color: rgba($color-sys-info, 0.8);
    }
  }

  &__update-modal {
    &-content {
      width: 32em;
      max-height: 80vh;

      @include respond-below(sm) {
        width: 100%;
      }
    }

    &-title {
      font-size: 1.5em;
      padding-bottom: 0.4em;
      border-bottom: 1px solid $color-light-grey;
    }

    &-msg {
      margin-top: 1.6em;
    }

    &-progress {
      margin-top: 0.6em;
    }

    &-errors {
      margin-top: 1.2em;
    }

    &-error {
      color: $color-flag-is-error;
      line-height: 1.5em;
      padding: 0.2em 0;

      &:not(:first-child) {
        border-top: 1px solid $color-grey;
      }
    }

    &-btn {
      margin-top: 1.6em;
      width: 100%;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "IMPORT_BTN": "Import",
    "FILE_READ_ERROR": "Unable to read file content",
    "UPDATE_MODAL_TITLE": "Importing Impact logins",
    "UPDATE_DEALER_MSG": "Dealers updated: {updated}/{total}",
    "NOT_FOUND_ERROR": "{email}: not found",
    "LOGIN_ERROR": "{email}: {message}",
    "UNKNOWN_ERROR": "{email}: unknown error",
    "CLOSE_BTN": "Close"
  },
  "ka": {
    "IMPORT_BTN": "იმპორტი",
    "FILE_READ_ERROR": "ფაილის წაკითხვა ვერ მოხდა",
    "UPDATE_MODAL_TITLE": "ექაუნთების ატვრითვა",
    "UPDATE_DEALER_MSG": "დილერები განახლდა: {updated}/{total}",
    "NOT_FOUND_ERROR": "{email}: არ მოიძებნა",
    "LOGIN_ERROR": "{email}: {message}",
    "UNKNOWN_ERROR": "{email}: შეცდომა",
    "CLOSE_BTN": "დახურვა"
  },
  "ru": {
    "IMPORT_BTN": "Импорт",
    "FILE_READ_ERROR": "Не удалось прочитать файл",
    "UPDATE_MODAL_TITLE": "Импорт логинов Impact",
    "UPDATE_DEALER_MSG": "Дилеров обновлено: {updated}/{total}",
    "NOT_FOUND_ERROR": "{email}: не найдено",
    "LOGIN_ERROR": "{email}: {message}",
    "UNKNOWN_ERROR": "{email}: неизвестная ошибка",
    "CLOSE_BTN": "Закрыть"
  },
  "uk": {
    "IMPORT_BTN": "Імпорт",
    "FILE_READ_ERROR": "Не вдалося прочитати файл",
    "UPDATE_MODAL_TITLE": "Імпорт логінів Impact",
    "UPDATE_DEALER_MSG": "Дилерів оновлено: {updated}/{total}",
    "NOT_FOUND_ERROR": "{email}: не знайдено",
    "LOGIN_ERROR": "{email}: {message}",
    "UNKNOWN_ERROR": "{email}: невідома помилка",
    "CLOSE_BTN": "Закрити"
  }
}
</i18n>
