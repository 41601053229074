<template>
  <button
    class="op-delete dealer-op dealer-op__btn"
    @click="onClick"
  >
    <ui-icon
      class="dealer-op__btn-ico"
      icon="trash-alt"
    />

    <span class="dealer-op__btn-txt">
      {{ $t('DELETE_BTN') }}
    </span>
  </button>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { Identity } from 'Models/Identity'
import { confirmAction } from 'Utils/confirmAction'
import { DeleteDealer } from '../../commands/DeleteDealer'

export default {
  name: 'op-delete',
  components: {
    UiIcon,
  },

  props: {
    identity: { type: Identity, required: true },
  },

  methods: {
    async onClick () {
      const msg = this.$t('DELETE_CONFIRM_MSG', { name: this.identity.fullName })
      if (!await confirmAction(msg)) {
        return
      }

      const cmd = new DeleteDealer({ dealerEntry: this.identity })
      await cmd.execute()
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/dealers-operations.scss";
</style>

<i18n>
{
  "en": {
    "DELETE_BTN": "Delete dealer account",
    "DELETE_CONFIRM_MSG": "Are you sure you want to delete {name}? The action is irreversible."
  },
  "ka": {
    "DELETE_BTN": "ექაუნთის წაშლა",
    "DELETE_CONFIRM_MSG": "გსურთ რომ წაშალოთ {name}?"
  },
  "ru": {
    "DELETE_BTN": "Удалить",
    "DELETE_CONFIRM_MSG": "Уверены, что желаете удалить {name}? Действие необратимо."
  },
  "uk": {
    "DELETE_BTN": "Вилучити",
    "DELETE_CONFIRM_MSG": "Впевнені, що бажаєте вилучити {name}? Дія незворотна."
  }
}
</i18n>
