<template>
  <div class="dealer-cell-operations dealers-cmn__cell">
    <ui-dropdown
      class="dealer-cell-operations__dropdown"
      ref="dropdownComponent"
      :clickaway="isClickawayEnabled"
      bubble
    >
      <button class="dealer-cell-operations__dropdown-trigger">
        <ui-icon
          class="dealer-cell-operations__dropdown-trigger-ico"
          icon="dots-v"
        />
      </button>

      <template slot="content">
        <div class="dealer-cell-operations__dropdown-content">
          <op-edit-details
            class="dealer-cell-operations__op"
            :identity="identity"
          />

          <op-edit-credentials
            class="dealer-cell-operations__op"
            :identity="identity"
            @op-started="isClickawayEnabled = false"
            @op-finished="isClickawayEnabled = true"
          />

          <op-block
            class="dealer-cell-operations__op"
            :identity="identity"
          />

          <op-delete
            class="dealer-cell-operations__op"
            :identity="identity"
          />
        </div>
      </template>
    </ui-dropdown>
  </div>
</template>

<script>
import { UiIcon, UiDropdown } from '@shelf.network/ui-kit'

import OpEditDetails from './operations/OpEditDetails'
import OpEditCredentials from './operations/OpEditCredentials'
import OpBlock from './operations/OpBlock'
import OpDelete from './operations/OpDelete'

import { Identity } from 'Models/Identity'

export default {
  name: 'dealer-cell-operations',
  components: {
    UiIcon,
    UiDropdown,

    OpEditDetails,
    OpEditCredentials,
    OpBlock,
    OpDelete,
  },

  props: {
    identity: { type: Identity, required: true },
  },

  data () {
    return {
      isClickawayEnabled: true,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/dealers.scss";

.dealer-cell-operations {
  /deep/ .ui-dropdown__content {
    transform: unset;
    left: unset;
    right: 0;
    border-radius: 1em;
    z-index: z-index(1);

    &::before {
      left: unset;
      right: 1.2em;
    }
  }

  &__dropdown {
    &-trigger {
      background: $color-light-grey;
      border: none;
      text-decoration: none;
      text-align: center;
      color: $color-ui-default;
      width: 3.55em;
      height: 3.55em;
      border-radius: 50%;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transition: all 180ms;

      &-ico {
        display: block;
        color: $color-ui-secondary;
        font-size: 1.2em;
        padding-top: 0.15em;
      }
    }

    &-content {
      display: grid;
      grid: auto-flow auto / auto;
      gap: 0.75em;
      padding: 1.25em 0;
      min-width: 20em;
    }
  }
}
</style>
