<template>
  <div class="dealer-cell-email dealers-cmn__cell">
    <template v-if="email">
      <a
        class="dealer-cell-email__link dealers-cmn__str
          dealers-cmn__str_nb dealers-cmn__str_sec
        "
        :title="email"
        :href="`mailto:${email}`"
      >
        {{ email }}
      </a>
    </template>

    <template v-else>
      <span class="dealers-cmn__str dealers-cmn__str_sec">
        {{ $t('COMMON.SYMBOLS.MDASH') }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'dealer-cell-email',

  props: {
    email: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/dealers.scss";

.dealer-cell-email {
  &__link {
    text-decoration: none;
  }
}
</style>
