<template>
  <ui-portal>
    <ui-modal
      class="dealer-create-modal"
      :title="$t('CREATE_DEALER_TITLE')"
      :show-close-btn="!isCreating"
      @close="close"
    >
      <form
        class="dealer-create-modal__form"
        :class="{ 'dealer-create-modal__form_loading': isCreating }"
        @submit.prevent="submit"
      >
        <div class="dealer-create-modal__field">
          {{ $t('LOGIN_LBL') }}
          <ui-select
            v-model="form.login"
            fill="frame"
            look="secondary"
            class="dealer-create-modal__field-input"
            :options="managedLoginsOptions"
            :placeholder="$t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
            :is-error="formErrors.login.length > 0"
            @input="formErrors.login = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.login"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-select>
        </div>

        <div class="dealer-create-modal__field">
          {{ $t('FIRST_NAME_LBL') }}
          <ui-text
            class="dealer-create-modal__field-input"
            :placeholder="$t('FIRST_NAME_PH')"
            :maxlength="30"
            v-model="form.firstName"
            :is-error="formErrors.firstName.length > 0"
            @input="formErrors.firstName = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.firstName"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-text>
        </div>

        <div class="dealer-create-modal__field">
          {{ $t('LAST_NAME_LBL') }}
          <ui-text
            class="dealer-create-modal__field-input"
            :placeholder="$t('LAST_NAME_PH')"
            :maxlength="30"
            v-model="form.lastName"
            :is-error="formErrors.lastName.length > 0"
            @input="formErrors.lastName = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.lastName"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-text>
        </div>

        <div class="dealer-create-modal__field">
          {{ $t('EMAIL_LBL') }}
          <ui-text
            class="dealer-create-modal__field-input"
            type="email"
            :placeholder="$t('EMAIL_PH')"
            v-model="form.email"
            :is-error="formErrors.email.length > 0"
            @input="formErrors.email = []"
          >
            <ui-icon
              class="dealer-create-modal__field-ico"
              icon="message-alt"
              slot="dock-right"
            />
            <span
              slot="error"
              v-for="err of formErrors.email"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-text>
        </div>

        <div class="dealer-create-modal__field">
          {{ $t('PHONE_LBL') }}
          <ui-phone
            class="dealer-create-modal__field-input"
            v-model="form.phone"
            :is-error="formErrors.phone.length > 0"
            :pinned-countries="phoneCountries"
            filter-countries
            @input="formErrors.phone = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.phone"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-phone>
        </div>

        <div class="dealer-create-modal__field">
          {{ $t('LOCATION_LBL') }}
          <select-location
            class="dealer-create-modal__field-input"
            v-model="form.location"
            :is-error="formErrors.location.length > 0"
            @input="formErrors.location = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.location"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </select-location>
        </div>

        <div class="dealer-create-modal__field">
          {{ $t('PASSWORD_LBL') }}
          <ui-text
            class="dealer-create-modal__field-input"
            type="password"
            :placeholder="$t('PASSWORD_PH')"
            v-model="form.password"
            :is-error="formErrors.password.length > 0"
            @input="formErrors.password = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.password"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-text>
        </div>

        <div class="dealer-create-modal__field">
          {{ $t('PASSWORD_CONFIRM_LBL') }}
          <ui-text
            class="dealer-create-modal__field-input"
            type="password"
            :placeholder="$t('PASSWORD_CONFIRM_PH')"
            v-model="form.passwordConfirm"
            :is-error="formErrors.passwordConfirm.length > 0"
            @input="formErrors.passwordConfirm = []"
          >
            <span
              slot="error"
              v-for="err of formErrors.passwordConfirm"
              :key="err.keyword"
            >
              {{ jsvLocalizeError(err) }}
            </span>
          </ui-text>
        </div>

        <div class="dealer-create-modal__buttons">
          <ui-button
            type="submit"
            class="dealer-create-modal__btn"
          >
            {{ $t('CREATE_DEALER_BTN') }}
          </ui-button>

          <ui-button
            @click="close"
            type="button"
            class="dealer-create-modal__btn"
            look="secondary"
            fill="frame-hover"
          >
            {{ $t('CANCEL_BTN') }}
          </ui-button>
        </div>
      </form>

      <ui-spinner
        class="dealer-create-modal__loader"
        overlay
        type="pills"
        :show="isCreating"
      />
    </ui-modal>
  </ui-portal>
</template>

<script>
import {
  UiButton,
  UiSelect,
  UiText,
  UiPhone,
  UiSpinner,
  UiIcon,
  UiModal,
  UiPortal,
} from '@shelf.network/ui-kit'

import SelectLocation from 'Common/SelectLocation'

import { mapGetters } from 'vuex'
import { extensionDealersGetters } from '../store/types'

import { CreateDealer } from '../commands/CreateDealer'
import { Location } from 'Models/Location'
import { UserEntry } from 'Models/UserEntry'

import { PHONE_COUNTRIES } from 'Constants/phoneCountries'
import { scrollToErrorInModal } from 'Utils/scrollTo'

import validationSchema from '../json-schemas/create-validation.json'
import { jsv } from 'Utils/jsv'
import { jsvLocalizeError } from 'Utils/jsvLocalizeError'
import { jsvMimicryError } from 'Utils/jsvMimicryError'

export default {
  name: 'dealer-create-modal',
  components: {
    UiButton,
    UiSelect,
    UiText,
    UiPhone,
    UiSpinner,
    UiIcon,
    UiModal,
    UiPortal,
    SelectLocation,
  },

  data () {
    return {
      form: {
        login: '',
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        location: '',
        password: '',
        passwordConfirm: '',
      },
      formErrors: {
        login: [],
        firstName: [],
        lastName: [],
        email: [],
        phone: [],
        location: [],
        password: [],
        passwordConfirm: [],
      },
      isCreating: false,
    }
  },

  computed: {
    ...mapGetters('ui/extension-dealers', {
      managedLogins: extensionDealersGetters.MANAGED_LOGINS,
    }),

    managedLoginsOptions () {
      return [
        { value: 'null', label: this.$t('COMMON.NOT_ASSIGNED_MSG') },
        ...this.managedLogins.map(value => ({ value, label: value }))
      ]
    },

    phoneCountries: () => PHONE_COUNTRIES,
  },

  created () {
    if (this.managedLogins.length) {
      this.form.login = this.managedLogins[0]
    }
  },

  methods: {
    checkFormValid () {
      if (Object.values(this.formErrors).some(el => el.length > 0)) return false

      const result = jsv.validate(validationSchema, this.form)
      if (result.isValid) return true

      Object.assign(this.formErrors, result.byField())
      return false
    },

    async submit () {
      if (!this.checkFormValid()) {
        scrollToErrorInModal()
        return
      }

      this.isCreating = true
      try {
        await this.createDealer()
        this.close()
      } catch (error) {
        if (error.httpStatus === 409) {
          const responseErrors = error.originalError.response.data.errors
          if (responseErrors.find(el => el.code === 'email')) {
            this.formErrors.email.push(jsvMimicryError(this.$t('EMAIL_CONFLICT_ERR')))
          }

          if (responseErrors.find(el => el.code.includes('phone'))) {
            this.formErrors.phone.push(jsvMimicryError(this.$t('PHONE_CONFLICT_ERR')))
          }
          scrollToErrorInModal()
        }
      }

      this.isCreating = false
    },

    async createDealer () {
      const locationSplit = Location.fromString(this.form.location)
      const cmd = new CreateDealer({
        attributes: {
          accountType: UserEntry.accountTypesEnum.dealer,
          firstName: this.form.firstName.trim(),
          lastName: this.form.lastName.trim(),
          phoneNumber: this.form.phone,
          email: this.form.email.trim().toLowerCase(),
          platformId: this.platformId,
          password: this.form.password,
          login: this.form.login,
          ...(this.form.location
            ? {
              countryId: Number(locationSplit.countryId),
              region: String(locationSplit.regionId),
              cityId: Number(locationSplit.cityId),
            } : {}
          ),
        },
        isForcedErrorThrow: true,
      })
      await cmd.execute()
    },

    close () {
      this.$emit('close')
    },

    jsvLocalizeError,
  },
}
</script>

<style scoped lang="scss">
.dealer-create-modal {
  &__form {
    max-width: 100%;
    width: 33em;
    padding: 2em 0.5em;

    &_loading {
      filter: blur(0.2em);
    }
  }

  &__field {
    margin-bottom: 2em;

    &-input {
      margin-top: 0.4em;
    }

    &-ico {
      color: $color-ui-secondary;
      font-size: 1.3em;
      vertical-align: middle;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5em;
    padding-top: 2em;
    border-top: 1px solid $color-light-grey;

    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  &__btn {
    margin: 1.2em 0;
    min-width: 15em;

    @include respond-below(sm) {
      min-width: 0;
      margin: 1em 0;
    }
  }

  & /deep/ .modal__content {
    max-width: 36em;

    @include respond-below(sm) {
      max-width: unset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CREATE_DEALER_BTN": "Create dealer",
    "CREATE_DEALER_TITLE": "Dealer create",
    "CANCEL_BTN": "Cancel",
    "LOGIN_LBL": "IMPACT LOGIN",
    "PHONE_LBL": "PHONE NUMBER",
    "PHONE_CONFLICT_ERR": "Phone is already in use",
    "FIRST_NAME_LBL": "FIRST NAME",
    "FIRST_NAME_PH": "John",
    "LAST_NAME_LBL": "LAST NAME",
    "LAST_NAME_PH": "Doe",
    "EMAIL_LBL": "EMAIL",
    "EMAIL_PH": "address@example.com",
    "EMAIL_CONFLICT_ERR": "Email is already in use",
    "LOCATION_LBL": "LOCATION (optional)",
    "PASSWORD_LBL": "DEALER PASSWORD",
    "PASSWORD_PH": "Pick a password",
    "PASSWORD_CONFIRM_LBL": "PASSWORD AGAIN",
    "PASSWORD_CONFIRM_PH": "Repeat the password"
  },
  "ka": {
    "CREATE_DEALER_BTN": "ახალი შექმნა",
    "CREATE_DEALER_TITLE": "ახალი შექმნა",
    "CANCEL_BTN": "დახურვა",
    "LOGIN_LBL": "IMPACT-ᲖᲔ ᲨᲔᲡᲕᲚᲐ",
    "PHONE_LBL": "ᲢᲔᲚᲔᲤᲝᲜᲘ",
    "PHONE_CONFLICT_ERR": "ტელეფონი უკვე რეგისტრირებულია",
    "FIRST_NAME_LBL": "ᲡᲐᲮᲔᲚᲘ",
    "FIRST_NAME_PH": "ჯონი",
    "LAST_NAME_LBL": "ᲒᲕᲐᲠᲘ",
    "LAST_NAME_PH": "გია",
    "EMAIL_LBL": "ᲔᲚ.ᲤᲝᲡᲢᲐ",
    "EMAIL_PH": "address@example.com",
    "EMAIL_CONFLICT_ERR": "ელ.ფოსტრა უკვე რეგისტრირებულია",
    "LOCATION_LBL": "ᲚᲝᲙᲐᲪᲘᲐ (არასავალდებულო)",
    "PASSWORD_LBL": "ახალი ᲞᲐᲠᲝᲚᲘ",
    "PASSWORD_PH": "აარჩიეთ პაროლი",
    "PASSWORD_CONFIRM_LBL": "ᲒᲐᲘᲛᲔᲝᲠᲔᲗ ᲞᲐᲠᲝᲚᲘ",
    "PASSWORD_CONFIRM_PH": "გაიმეორეთ პაროლი"
  },
  "ru": {
    "CREATE_DEALER_BTN": "Создать дилера",
    "CREATE_DEALER_TITLE": "Создание дилера",
    "CANCEL_BTN": "Закрыть",
    "LOGIN_LBL": "ЛОГИН IMPACT",
    "PHONE_LBL": "НОМЕР ТЕЛЕФОНА",
    "PHONE_CONFLICT_ERR": "Телефон уже используется",
    "FIRST_NAME_LBL": "ИМЯ",
    "FIRST_NAME_PH": "Иван",
    "LAST_NAME_LBL": "ФАМИЛИЯ",
    "LAST_NAME_PH": "Иванов",
    "EMAIL_LBL": "ЭЛЕКТРОННАЯ ПОЧТА",
    "EMAIL_PH": "address@example.com",
    "EMAIL_CONFLICT_ERR": "Почта уже используется",
    "LOCATION_LBL": "ЛОКАЦИЯ (опционально)",
    "PASSWORD_LBL": "ПАРОЛЬ ДИЛЕРА",
    "PASSWORD_PH": "Придумайте пароль",
    "PASSWORD_CONFIRM_LBL": "ПАРОЛЬ ПОВТОРНО",
    "PASSWORD_CONFIRM_PH": "Повторите пароль"
  },
  "uk": {
    "CREATE_DEALER_BTN": "Створити дилера",
    "CREATE_DEALER_TITLE": "Створення дилера",
    "CANCEL_BTN": "Закрити",
    "LOGIN_LBL": "ЛОГІН IMPACT",
    "PHONE_LBL": "НОМЕР ТЕЛЕФОНУ",
    "PHONE_CONFLICT_ERR": "Телефон вже використовується",
    "FIRST_NAME_LBL": "ІМ’Я",
    "FIRST_NAME_PH": "Тарас",
    "LAST_NAME_LBL": "ПРІЗВИЩЕ",
    "LAST_NAME_PH": "Шевченко",
    "EMAIL_LBL": "ЕЛ. ПОШТА",
    "EMAIL_PH": "address@example.com",
    "EMAIL_CONFLICT_ERR": "Пошта вше використовується",
    "LOCATION_LBL": "ЛОКАЦІЯ (опціонально)",
    "PASSWORD_LBL": "ПАРОЛЬ ДИЛЕРА",
    "PASSWORD_PH": "Придумайте пароль",
    "PASSWORD_CONFIRM_LBL": "ПАРОЛЬ ПОВТОРНО",
    "PASSWORD_CONFIRM_PH": "Повторіть пароль"
  }
}
</i18n>
