<template>
  <div class="dealer-card-tab-attrs">
    <form class="dealer-card-tab-attrs__form">
      <ui-row class="dealer-card-tab-attrs__row">
        <ui-col
          class="dealer-card-tab-attrs__col"
          lg="6"
          xs="12"
        >
          <div class="dealer-card-tab-attrs__field">
            <label class="dealer-card-tab-attrs__field-lbl">
              {{ $t('LOGIN_LBL') }}
            </label>

            <ui-select
              :value="form.login"
              fill="frame"
              class="dealer-card-tab-attrs__field-input"
              :options="managedLoginsOptions"
              :placeholder="$t('COMMON.SELECT_FIELD.SELECT_ITEM_PH')"
              @input="submitLogin"
            />
          </div>
        </ui-col>
      </ui-row>

      <ui-row class="dealer-card-tab-attrs__row">
        <ui-col
          class="dealer-card-tab-attrs__col"
          lg="6"
          xs="12"
        >
          <div class="dealer-card-tab-attrs__field">
            <label class="dealer-card-tab-attrs__field-lbl">
              {{ $t('LOCATION_LBL') }}
            </label>

            <select-location
              v-model="form.location"
              class="dealer-card-tab-attrs__field-input"
              fill="frame"
              :is-error="formErrors.location.length > 0"
              @input="formErrors.location = []; submitLocation()"
            >
              <p
                class="dealer-card-tab-attrs__field-error"
                slot="error"
                v-for="(err, i) of formErrors.email"
                :key="i"
              >
                {{ jsvLocalizeError(err) }}
              </p>
            </select-location>
          </div>
        </ui-col>

        <ui-col
          class="dealer-card-tab-attrs__col"
          lg="6"
          xs="12"
        >
          <div class="dealer-card-tab-attrs__field">
            <label class="dealer-card-tab-attrs__field-lbl">
              {{ $t('EMAIL_LBL') }}
            </label>

            <ui-text
              v-model="form.email"
              class="
              dealer-card-tab-attrs__field-input
              dealer-card-tab-attrs__field-input_email
            "
              :placeholder="$t('EMAIL_PH')"
              :is-error="formErrors.email.length > 0"
              @input="formErrors.email = []"
              @blur="submit({ email: form.email })"
            >
              <p
                class="dealer-card-tab-attrs__field-error"
                slot="error"
                v-for="(err, i) of formErrors.email"
                :key="i"
              >
                {{ jsvLocalizeError(err) }}
              </p>

              <template v-if="dealerEntry.isEmailVerified">
                <ui-icon
                  slot="dock-right"
                  class="dealer-card-tab-attrs__check-ico-docked"
                  icon="success"
                  :title="$t('VERIFIED_EMAIL_HINT')"
                />
              </template>
              <template v-else>
                <button
                  class="dealer-card-tab-attrs__verify-email-btn"
                  type="button"
                  @click="verifyEmail"
                  :disabled="isProcessingVerifyEmail"
                >
                  {{ $t('VERIFY_EMAIL_BTN') }}

                  <ui-spinner
                    v-if="isProcessingVerifyEmail"
                    class="cell-participant-reject__spinner"
                    type="pills"
                    :overlay="true"
                  />
                </button>
              </template>
            </ui-text>
          </div>
        </ui-col>
      </ui-row>
    </form>
  </div>
</template>

<script>
import {
  UiRow,
  UiCol,
  UiIcon,
  UiSpinner,
  UiText,
  UiSelect
} from '@shelf.network/ui-kit'
import SelectLocation from 'Common/SelectLocation'

import { VerifyDealerEmail } from '../../commands/VerifyDealerEmail'
import { UpdateDealer } from '../../commands/UpdateDealer'

import { jsv } from 'Utils/jsv'
import validationSchema from '../../json-schemas/update-attrs-validation.json'

import { extensionDealersGetters } from '../../store/types'
import { mapGetters } from 'vuex'

import { jsvMimicryError } from 'Utils/jsvMimicryError'
import { jsvLocalizeError } from 'Utils/jsvLocalizeError'

import { Location } from 'Models/Location'
import { UserEntry } from 'Models/UserEntry'

import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'

export default {
  name: 'dealer-card-tab-attrs',
  components: {
    UiRow,
    UiCol,
    UiIcon,
    UiSpinner,
    UiText,
    UiSelect,
    SelectLocation
  },

  props: {
    dealerEntry: {
      type: UserEntry,
      required: true
    }
  },

  data () {
    return {
      form: {},
      formErrors: {},
      isProcessingVerifyEmail: false,
    }
  },

  computed: {
    ...mapGetters('ui/extension-dealers', {
      managedLogins: extensionDealersGetters.MANAGED_LOGINS,
      identity: extensionDealersGetters.IDENTITY,
    }),

    managedLoginsOptions () {
      return [
        { value: 'null', label: this.$t('COMMON.NOT_ASSIGNED_MSG') },
        ...this.managedLogins.map(value => ({ value, label: value }))
      ]
    },
  },

  watch: {
    dealerEntry: {
      handler (value) {
        Object.assign(this.form, this.entryToForm())
      },
    },

    identity: {
      handler (value) {
        Object.assign(this.form, this.entryToForm())
      },
    }
  },

  created () {
    this.form = this.entryToForm()
    this.formErrors = this.entryToFormErrorsDefault()
  },

  methods: {
    entryToForm () {
      return {
        email: this.dealerEntry.email,
        location: this.dealerEntry.location.toString(),
        login: this.managedLogins.includes(this.identity.login)
          ? this.identity.login
          : 'null'
      }
    },

    entryToFormErrorsDefault () {
      const newObjEntries = Object.entries(this.entryToForm())
        .map(([key]) => [key, []])
      return Object.fromEntries(newObjEntries)
    },

    checkChanged (form) {
      const oldForm = this.entryToForm()
      const diff = pickBy(form, (val, key) => val !== oldForm[key])
      return Object.keys(diff).length > 0
    },

    checkFormValid (form) {
      if (Object.values(this.formErrors).some(el => el.length > 0)) return false

      const result = jsv.validate(validationSchema, form)
      if (result.isValid) return true

      const formErrors = pick(result.byField(), Object.keys(form))
      if (!Object.keys(formErrors).length) return true

      Object.assign(this.formErrors, formErrors)
      return false
    },

    async verifyEmail () {
      if (this.isProcessingVerifyEmail) return
      this.isProcessingVerifyEmail = true
      const cmd = new VerifyDealerEmail({ dealerEntry: this.dealerEntry })
      await cmd.execute()
      this.isProcessingVerifyEmail = false
    },

    submitLocation () {
      this.submit({ location: this.form.location })
    },

    async submitLogin (value) {
      this.form.login = value
      await this.submit({ login: this.form.login })
    },

    async submit (form) {
      if (!this.checkChanged(form) || !this.checkFormValid(form)) return

      const attributes = { relationships: {} }

      if (form.hasOwnProperty('email')) {
        attributes.relationships.basic = {
          email: form.email.toLowerCase(),
          emailVerified: false
        }
      }

      if (form.hasOwnProperty('location')) {
        const locationSplit = Location.fromString(form.location)

        attributes.relationships.location = {
          id: form.location,
          relationships: {
            country: { id: locationSplit.countryId },
            region: { id: locationSplit.regionId },
            city: { id: locationSplit.cityId },
          }
        }
      }

      if (form.hasOwnProperty('login')) {
        // TODO: create separate action for updating impact login
        if (!attributes.relationships.hasOwnProperty('basic')) {
          attributes.relationships.basic = {}
        }
        attributes.relationships.basic.login = form.login
      }

      const cmd = new UpdateDealer({
        isForcedErrorThrow: true,
        dealerEntry: this.dealerEntry,
        attributes,
      })

      try {
        await cmd.execute()
      } catch (error) {
        if (error.httpStatus === 409) {
          const responseErrors = error.originalError.response.data.errors
          if (responseErrors.find(el => el.code === 'email')) {
            this.formErrors.email.push(jsvMimicryError(this.$t('EMAIL_CONFLICT_ERR')))
          }
        }

        Object.assign(this.form, this.entryToForm())
      }
    },

    jsvLocalizeError,
  }
}
</script>

<style lang="scss" scoped>
.dealer-card-tab-attrs {
  &__col {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  &__field {
    &-input {
      margin-top: 1em;

      &.ui-text /deep/ .ui-text__input {
        &[fill][look] {
          background-color: $color-light;
          border-color: $color-grey;

          &:focus {
            border-color: $color-ui-default;
          }
        }
      }

      &.ui-select /deep/ .ui-select__button {
        & > .ui-button__button[fill][look] {
          background-color: $color-light;
          border-color: $color-grey;
        }

        &.ui-select__button_is-open > .ui-button__button[fill][look] {
          border-color: $color-ui-default;
        }
      }
    }
  }

  &__check-ico-docked {
    color: $color-flag-is-success;
    font-size: 2em;
    display: grid;
    height: inherit;
    width: inherit;
    align-items: center;
    justify-content: end;
  }

  &__verify-email-btn {
    color: $color-sys-info;
    background: none;
    border: none;
    position: relative;
    padding: 0 0.5em;
    margin-left: auto;
    display: block;

    &:hover {
      color: mix($color-sys-info, $color-ui-default, 80);
    }
  }
}
</style>

<i18n>
{
  "en": {
    "LOGIN_LBL": "IMPACT LOGIN",
    "EMAIL_LBL": "EMAIL",
    "EMAIL_PH": "Enter an email",
    "EMAIL_CONFLICT_ERR": "Email is already in use",
    "VERIFY_EMAIL_BTN": "Verify",
    "VERIFIED_EMAIL_HINT": "Email verified",
    "LOCATION_LBL": "LOCATION",
    "OPEN_ITEM_WARNING": "Dealer has an open item, in case of account switch counter bid will be restricted, continue?"
  },
  "ka": {
    "LOGIN_LBL": "IMPACT-ᲖᲔ ᲨᲔᲡᲕᲚᲐ",
    "EMAIL_LBL": "ᲔᲚ.ᲤᲝᲡᲢᲐ",
    "EMAIL_PH": "შეიყვანე ელ.ფოსტა",
    "EMAIL_CONFLICT_ERR": "ელ.ფოსტრა უკვე რეგისტრირებულია",
    "VERIFY_EMAIL_BTN": "ვერიფიკაცია",
    "VERIFIED_EMAIL_HINT": "ელ.ფოსტრა დადასტურდა",
    "LOCATION_LBL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "OPEN_ITEM_WARNING": "დილერს On approval - ზე ყავს მანქანა და ვერ შეძლებს ბიდის დადებას, ნამდვილად გსურს ექაუნთის ცვლილება?"
  },
  "ru": {
    "LOGIN_LBL": "ЛОГИН IMPACT",
    "EMAIL_LBL": "ЭЛЕКТРОННАЯ ПОЧТА",
    "EMAIL_PH": "Введите почту",
    "EMAIL_CONFLICT_ERR": "Почта уже используется",
    "VERIFY_EMAIL_BTN": "Подтвердить",
    "VERIFIED_EMAIL_HINT": "Почта подтверждена",
    "LOCATION_LBL": "ЛОКАЦИЯ",
    "OPEN_ITEM_WARNING": "У дилера есть открытая позиция, в случае смены аккаунта встречная ставка будет ограничена, продолжить?"
  },
  "uk": {
    "LOGIN_LBL": "ЛОГІН IMPACT",
    "EMAIL_LBL": "ЕЛЕКТРОННА ПОШТА",
    "EMAIL_PH": "Введіть пошту",
    "EMAIL_CONFLICT_ERR": "Пошта вше використовується",
    "VERIFY_EMAIL_BTN": "Підтвердити",
    "VERIFIED_EMAIL_HINT": "Пошту підтверджено",
    "LOCATION_LBL": "ЛОКАЦІЯ",
    "OPEN_ITEM_WARNING": "У дилера є відкрита позиція, у разі зміни облікового запису зустрічна ставка буде обмежена, продовжити?"
  }
}
</i18n>
