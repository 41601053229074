<template>
  <div class="dealers-list-head dealers-cmn__row">
    <span class="dealers-list-head__cell">
      {{ $t('DEALER_HEAD_CELL') }}
    </span>

    <span class="dealers-list-head__cell">
      {{ $t('EMAIL_HEAD_CELL') }}
    </span>

    <span class="dealers-list-head__cell">
      {{ $t('LOGIN_HEAD_CELL') }}
    </span>

    <span class="dealers-list-head__cell">
      {{ $t('LOCATION_HEAD_CELL') }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'dealers-list-head',
}
</script>

<style lang="scss" scoped>
@import "../styles/dealers.scss";

.dealers-list-head {
  padding: 2em 3em 0;

  &__cell {
    @extend .dealers-cmn__cell;

    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "DEALER_HEAD_CELL": "DEALER",
    "EMAIL_HEAD_CELL": "EMAIL",
    "LOGIN_HEAD_CELL": "IMPACT LOGIN",
    "LOCATION_HEAD_CELL": "LOCATION"
  },
  "ka": {
    "DEALER_HEAD_CELL": "ᲓᲘᲚᲔᲠᲘ",
    "EMAIL_HEAD_CELL": "ᲔᲚ.ᲤᲝᲡᲢᲠᲐ",
    "LOGIN_HEAD_CELL": "IMPACT-ᲖᲔ ᲨᲔᲡᲕᲚᲐ",
    "LOCATION_HEAD_CELL": "ᲚᲝᲙᲐᲪᲘᲐ"
  },
  "ru": {
    "DEALER_HEAD_CELL": "ДИЛЕР",
    "EMAIL_HEAD_CELL": "ЭЛ. ПОЧТА",
    "LOGIN_HEAD_CELL": "ЛОГИН IMPACT",
    "LOCATION_HEAD_CELL": "ЛОКАЦИЯ"
  },
  "uk": {
    "DEALER_HEAD_CELL": "ДИЛЕР",
    "EMAIL_HEAD_CELL": "ЕЛ. ПОШТА",
    "LOGIN_HEAD_CELL": "ЛОГІН IMPACT",
    "LOCATION_HEAD_CELL": "ЛОКАЦІЯ"
  }
}
</i18n>
