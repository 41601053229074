<template>
  <ui-button
    @click="removeDealer"
    :is-disabled="isProcessingRemove"
    class="dealer-card-remove-btn"
    look="danger"
    fill="none"
  >
    <ui-icon
      class="dealer-card-remove-btn__ico"
      icon="trash-alt"
    />

    {{ $t('REMOVE_DEALER_BTN') }}

    <ui-spinner
      class="dealer-card-remove-btn__spinner"
      type="pills"
      overlay
      v-if="isProcessingRemove"
    />
  </ui-button>
</template>

<script>
import { UiButton, UiIcon, UiSpinner } from '@shelf.network/ui-kit'
import { UserEntry } from 'Models/UserEntry'
import { DeleteDealer } from '../../commands/DeleteDealer'
import { confirmAction } from 'Utils/confirmAction'

export default {
  name: 'dealer-card-remove-btn',

  components: {
    UiButton,
    UiIcon,
    UiSpinner,
  },

  props: {
    dealerEntry: {
      type: UserEntry,
      required: true,
    }
  },

  data () {
    return {
      isProcessingRemove: false,
    }
  },

  methods: {
    async removeDealer () {
      if (this.isProcessingRemove) return

      const msg = this.$t('REMOVE_CONFIRM_MSG', { name: this.dealerName })
      if (!await confirmAction(msg)) {
        return
      }

      this.isProcessingRemove = true
      const cmd = new DeleteDealer({ dealerEntry: this.dealerEntry })
      await cmd.execute()
      this.isProcessingRemove = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dealer-card-remove-btn {
  /deep/ .ui-button__button {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    position: relative;
  }

  &__ico {
    padding-right: 0.4em;
    font-size: 1.5em;
  }
}
</style>

<i18n>
{
  "en": {
    "REMOVE_DEALER_BTN": "Remove",
    "REMOVE_CONFIRM_MSG": "Are you sure you want to delete {name}? The action is irreversible."
  },
  "ka": {
    "REMOVE_DEALER_BTN": "წაშლა",
    "REMOVE_CONFIRM_MSG": "გსურთ რომ წაშალოთ {name}?"
  },
  "ru": {
    "REMOVE_DEALER_BTN": "Удалить",
    "REMOVE_CONFIRM_MSG": "Уверены, что желаете удалить {name}? Действие необратимо."
  },
  "uk": {
    "REMOVE_DEALER_BTN": "Вилучити",
    "REMOVE_CONFIRM_MSG": "Впевнені, що бажаєте вилучити {name}? Дія незворотна."
  }
}
</i18n>
