<template>
  <div
    class="
      dealer-cell-location
      dealers-cmn__cell
      dealers-cmn__str
      dealers-cmn__str_sec
    "
  >
    <template v-if="hasAnyLocationId">
      <ui-icon
        class="dealers-cmn__ico"
        icon="pin-alt"
      />

      <i18n-location
        class="dealer-cell-location__value"
        fill="none"
        look="secondary"
        :location="location"
      >
        {{ location }}
      </i18n-location>
    </template>

    <template v-else>
      <span class="dealers-cmn__str dealers-cmn__str_sec">
        {{ $t('COMMON.SYMBOLS.MDASH') }}
      </span>
    </template>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { Location } from 'Models/Location'
import i18nLocation from 'Common/I18nLocation'

export default {
  name: 'dealer-cell-location',

  components: {
    UiIcon,
    i18nLocation,
  },

  props: {
    location: {
      type: Location,
      default: null,
    },
  },

  computed: {
    hasAnyLocationId () {
      return Boolean(
        this.location.countryId ||
        this.location.cityId ||
        this.location.regionId
      )
    },
  },

}
</script>

<style lang="scss" scoped>
@import "../styles/dealers.scss";

.dealer-cell-location {
  position: relative;

  &.dealers-cmn__cell {
    grid: auto / auto 1fr;
  }

  /deep/ .ui-text {
    line-height: unset;
    font-weight: inherit;
    width: 100%;
  }

  /deep/ .ui-text__input {
    padding: 0;
    text-overflow: ellipsis;
    border-radius: unset;
    font-weight: inherit;
    flex: 1;
    line-height: 1.5;

    &[fill][look=secondary]:hover {
      color: $color-ui-secondary;
    }
  }

  /deep/ .ui-text__dock {
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    width: unset;
    height: unset;
  }
}
</style>
