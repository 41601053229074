<template>
  <div class="op-edit-credentials">
    <button
      class="op-edit-credentials dealer-op dealer-op__btn"
      @click="showEditCredentialsModal()"
    >
      <ui-icon
        class="dealer-op__btn-ico"
        icon="lock"
      />

      <span class="dealer-op__btn-txt">
        {{ $t('EDIT_PASSWORD_BTN') }}
      </span>
    </button>

    <op-edit-credentials-modal
      class="op-edit-credentials__modal"
      v-if="isEditCredentialsModalShown"
      :identity="identity"
      @close="hideEditCredentialsModal()"
    />
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import { Identity } from 'Models/Identity'
import OpEditCredentialsModal from './OpEditCredentialsModal'

export default {
  name: 'op-edit-credentials',
  components: {
    UiIcon,
    OpEditCredentialsModal,
  },

  props: {
    identity: { type: Identity, required: true },
  },

  data () {
    return {
      isEditCredentialsModalShown: false,
    }
  },

  methods: {
    onClick () {
      this.isEditCredentialsModalShown = true
    },

    showEditCredentialsModal () {
      this.isEditCredentialsModalShown = true
      this.$emit('op-started')
    },

    hideEditCredentialsModal () {
      this.isEditCredentialsModalShown = false
      this.$emit('op-finished')
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/dealers-operations.scss";
</style>

<i18n>
{
  "en": {
    "EDIT_PASSWORD_BTN": "Edit dealer password"
  },
  "ka": {
    "EDIT_PASSWORD_BTN": "პაროლის შეცვლა"
  },
  "ru": {
    "EDIT_PASSWORD_BTN": "Сменить пароль"
  },
  "uk": {
    "EDIT_PASSWORD_BTN": "Змінити пароль"
  }
}
</i18n>
