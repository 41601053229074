import { DealerCommand } from './DealerCommand'
import { sdk } from 'Services/shelfNetworkSdk'

/**
 * @typedef {object} UpdateDealerArgsDef
 * @property {object} newPassword New attributes to apply to the dealer
 *
 * @typedef {import('./DealerCommand').DealerCommandArgs} DealerCommandArgs
 * @typedef {DealerCommandArgs & UpdateDealerArgsDef} ChangeDealerPwdArgs
 */

export class ChangeDealerPwd extends DealerCommand {
  /**
   * Create a configured UpdateDealer command
   * @param {ChangeDealerPwdArgs} opts
   */
  constructor (opts) {
    super(opts)

    this._newPassword = opts.newPassword
  }

  async execute () {
    try {
      await sdk.auth.changePassword(
        null,
        this._newPassword,
        this._dealerEntry.id
      )

      const msg = this.$t('CMD.USERS.UPDATE.SUCCESS_MSG', { name: this._getName() })
      this._reportSuccess(msg)
    } catch (error) {
      const msg = this.$t('CMD.USERS.UPDATE.FAILURE_MSG', { name: this._getName() })
      this._reportError(error, msg)
    }
  }
}
