<template>
  <list-grouped
    class="dealers-list"
    :list="list"
    :list-total-count="0"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="DEALERS_PAGE_LIMIT"
    :load-more="loadMore"
    :head-component="DealersListHead"
    :item-component="DealerItem"
    :item-skeleton-component="DealerItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  >
    <template slot="actions">
      <dealers-import />
      <dealers-export />
    </template>
  </list-grouped>
</template>

<script>
import ListGrouped from 'Common/ListGrouped'

import DealerItem from './DealerItem'
import DealerItemSkeleton from './DealerItemSkeleton'
import DealersListHead from './DealersListHead'
import DealersExport from './DealersExport'
import DealersImport from './DealersImport'

import { mapGetters } from 'vuex'
import { extensionDealersGetters } from '../store/types'

import { DEALERS_PAGE_LIMIT } from '../constants'
import { validateArrayOf } from 'Models/modelUtils'
import { Identity } from 'Models/Identity'

export default {
  name: 'dealers-list',
  components: {
    ListGrouped,
    DealersExport,
    DealersImport
  },

  props: {
    list: {
      type: Array,
      required: true,
      validator: validateArrayOf(Identity),
    },

    loadMore: {
      type: Function,
      required: true,
    },
  },

  data () {
    return {
      DealerItem,
      DealerItemSkeleton,
      DealersListHead,
      DEALERS_PAGE_LIMIT,
      storeUnsubscriber: () => { },
    }
  },

  computed: {
    ...mapGetters('ui/extension-dealers', {
      isLoading: extensionDealersGetters.IS_LOADING,
      isNextLoading: extensionDealersGetters.IS_NEXT_LOADING,
    }),
  },

  beforeDestroy () {
    this.storeUnsubscriber()
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/dealers.scss";

.dealers-list {
  --list-padding-side: 3em;
}
</style>
